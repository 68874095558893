import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { IPreferences, PreferencesContext } from 'contexts/preferences';
import { buildClassName } from 'utils/build-class-name';
import { useUserContext } from 'contexts/user';
import useKeyboard from 'hooks/use-keyboard';
import WarningIcon from 'components/icon/warning-icon';
import LoadingSpinner from 'components/loading-spinner';
import LocationIcon from 'components/icon/location-icon';
import HomeIcon from 'components/icon/home-icon';
import NotificationIcon from 'components/icon/notification-icon';
import BuildingIcon from 'components/icon/building-icon';
import ClockIcon from 'components/icon/clock-icon';
import StarIcon from 'components/icon/star-icon';
import { trackEvent } from 'utils/google-tag-manager';
import { testIds } from 'constants/test-constants';
import LocationDropdownSection from './location-dropdown-section';
import pushToRoute from 'utils/push-to-route';
import defaultPopularCitiesData from 'components/suggested-location-dropdown/data.json';
import { useFeaturesContext, useThemeContext } from 'contexts';
import styles from './style.module.scss';
import { MapType } from 'data/search-predictions';
import useMapLocationChanger from 'hooks/use-map-location-changer';
import { ThemeNames } from 'types/themes';

import type SearchPrediction from 'data/search-predictions';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';

export type SearchSuggestions = {
  identifier: number;
  label: string;
  latitude?: number;
  longitude?: number;
  urlPath: string;
  hasAreaPage?: boolean;
  zoom: number;
};

export type SearchAgentPrediction = {
  identifier: number;
  label: string;
  group: string;
};

export type SearchResult = SearchPrediction | SearchSuggestions;

interface Props {
  searchPredictions: SearchPrediction[];
  setActivePrediction: (searchPrediction: any) => void;
  isActive: boolean;
  isLoading: boolean;
  isAreaSearch?: boolean;
  isAdvancedSearch?: boolean;
  locationQuery?: string;
  onClick: (searchPrediction: SearchPrediction) => void;
  className?: string;
  isTyping?: boolean;
  disablePopularSearches?: boolean;
  hideSearchPanel?: () => void;
  searchAgentPredictions?: SearchAgentPrediction[];
}

export function sortSearchPredictions(searchPredictions: SearchPrediction[]) {
  const locations = searchPredictions.filter(searchPrediction => searchPrediction.group === 'locations');
  const listings = searchPredictions.filter(searchPrediction => searchPrediction.group === 'listings');
  const schools = searchPredictions.filter(searchPrediction => searchPrediction.group === 'schools');
  const buildings = searchPredictions.filter(searchPrediction => searchPrediction.group === 'buildings');
  [...locations, ...listings, ...schools, ...buildings].forEach((searchPrediction, index) => searchPrediction.identifier = index);
  return {
    locations,
    listings,
    schools,
    buildings,
  };
}

export default function SuggestedLocationDropdown({ searchPredictions, setActivePrediction, isActive, isAreaSearch, isAdvancedSearch, locationQuery, isLoading, onClick, className, isTyping, hideSearchPanel, disablePopularSearches, searchAgentPredictions = []}: Props) {
  const { recentSearches } = useContext(PreferencesContext) as IPreferences;
  const { features } = useFeaturesContext();
  const { themeName } = useThemeContext();
  const router = useRouter();
  const isHomeAppraisal = router.asPath && router.asPath.includes('/home-appraisal');
  const [suggestedResults, setSuggestedResults] = useState(sortSearchPredictions(searchPredictions));
  const [selectedResult, setSelectedResult] = useState(0);
  const [resultHasContent, setResultHasContent] = useState(false);
  const { user, siteLocation, userPopularCities, userLocation, getUserLocationPopularCities } = useUserContext();
  const [updatedPopularSearches, setUpdatedPopularSearches] = useState([]);
  const [updatedRecentSearches, setUpdatedRecentSearches] = useState(recentSearches);
  const [isEnterKeyPressed, setIsEnterKeyPressed] = useState(false);
  const showRecentSearches = isAdvancedSearch && !locationQuery && recentSearches && !resultHasContent && !isLoading && !isAreaSearch;

  const notYetSearched = isAdvancedSearch && !locationQuery && !resultHasContent && !isLoading;
  const noResults = !resultHasContent && !isLoading && locationQuery;
  const showPopularSearches = (noResults || notYetSearched) && !disablePopularSearches;
  const showAgentPredictionSearches = !!searchAgentPredictions.length;

  const { listingParams } = useContext(PreferencesContext) as IPreferences;
  const map: MapType = useMapLocationChanger();

  const [flattenedResults, setFlattenedResults] = useState<any>([
    ...suggestedResults.locations,
    ...suggestedResults.listings,
    ...suggestedResults.buildings,
    ...suggestedResults.schools,
    ...[...new Set(showRecentSearches ? updatedRecentSearches : [])],
    ...[...new Set(showPopularSearches ? updatedPopularSearches : [])],
    ...[...new Set(showAgentPredictionSearches ? searchAgentPredictions : [])],
  ]);

  useEffect(() => {
    const { usPopularSearches, canadaPopularSearches } = defaultPopularCitiesData;

    const getUserCitiesInCountry = (): SearchSuggestions[] => {
      const filteredCities = userPopularCities?.filter(city => {
        const province = city.urlPath.split('-')[1];
        return countryCodeFromProvinceOrState(province) === siteLocation;
      }) || [];

      const activeLocationPopSearches = siteLocation === 'CA' ? canadaPopularSearches : usPopularSearches;
      const additionalCitiesNeeded = Array.isArray(filteredCities) ? Math.max(0, 10 - filteredCities.length) : 10;
      const additionalCities = activeLocationPopSearches.slice(0, additionalCitiesNeeded);

      return [...filteredCities, ...additionalCities];
    };

    const updatedCities = getUserCitiesInCountry();

    if (!userPopularCities?.length && userLocation && Object.keys(userLocation).length > 0) {
      getUserLocationPopularCities(userLocation).then(setUpdatedPopularSearches);
    } else {
      switch (themeName) {
      case ThemeNames.EXP_REALTY_CA:
        setUpdatedPopularSearches(updatedCities.length ? updatedCities : canadaPopularSearches);
        break;
      case ThemeNames.EXP_REALTY_US:
        setUpdatedPopularSearches(updatedCities.length ? updatedCities : usPopularSearches);
        break;
      case ThemeNames.ZOOCASA:
        setUpdatedPopularSearches(userPopularCities || []);
        break;
      default:
        setUpdatedPopularSearches(updatedCities);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPopularCities, siteLocation, userLocation, themeName]);


  useEffect(() => {
    updatedSearchesWithIdentifier();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRecentSearches, siteLocation, userPopularCities]);

  useEffect(() => {
    setSuggestedResults(sortSearchPredictions(searchPredictions));

    if (locationQuery?.length && !searchPredictions.length && !isLoading) {
      trackEvent('no-results', 'search-results', locationQuery);
    }

    setResultHasContent((!!searchPredictions.length || !!searchAgentPredictions.length) && !isLoading);
    setActivePrediction(flattenedResults.filter((item: SearchResult) => item.identifier === selectedResult || item.identifier === selectedResult)[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPredictions, searchAgentPredictions, isLoading]);

  useEffect(() => {
    setFlattenedResults([
      ...suggestedResults.locations,
      ...suggestedResults.listings,
      ...suggestedResults.buildings,
      ...suggestedResults.schools,
      ...[...new Set(showRecentSearches ? updatedRecentSearches : [])],
      ...[...new Set(showPopularSearches ? updatedPopularSearches : [])],
      ...[...new Set(showAgentPredictionSearches ? searchAgentPredictions : [])],
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedResults]);

  useEffect(() => {
    setActivePrediction(flattenedResults[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flattenedResults]);

  useEffect(() => {
    setActivePrediction(Object.values(flattenedResults as SearchResult).filter(({ identifier }) => identifier === selectedResult)[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResult]);

  useEffect(() => {
    if (isEnterKeyPressed) {
      navigateToEnteredLocation();
      setIsEnterKeyPressed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnterKeyPressed]);

  const updatedSearchesWithIdentifier = () => {
    let tempRecentSearch = recentSearches;
    if (isHomeAppraisal) tempRecentSearch = recentSearches?.filter(s => s.label !== 'city');
    const updatedRecentSearches = tempRecentSearch?.map((search, index) => {
      return {
        ...search,
        identifier: index,
      };
    }) as SearchPrediction[];
    const updatedPopularSearchesWithId = updatedPopularSearches?.map((search, index) => {
      return {
        ...search,
        identifier: (tempRecentSearch?.length || 0) + index,
      };
    });
    setUpdatedRecentSearches(updatedRecentSearches);
    setFlattenedResults([
      ...suggestedResults.locations,
      ...suggestedResults.listings,
      ...suggestedResults.buildings,
      ...suggestedResults.schools,
      ...[...new Set(showRecentSearches ? updatedRecentSearches : [])],
      ...[...new Set(showPopularSearches ? updatedPopularSearchesWithId : [])],
      ...[...new Set(showAgentPredictionSearches ? searchAgentPredictions : [])],
    ]);
  };

  const navigateDownListWithKeyboard = () => {
    if (isActive) {
      setSelectedResult(prev => Math.min(prev + 1, flattenedResults.length - 1));
    }
  };
  const navigateUpListWithKeyboard = () => {
    if (isActive) {
      setSelectedResult(prev => Math.max(prev - 1, 0));
    }
  };
  const navigateToEnteredLocation = () => {
    if (isActive) {
      const selectedLocation = Object.values(flattenedResults as SearchResult).filter(({ identifier }) => identifier === selectedResult)[0];
      if (selectedLocation) {
        setActivePrediction(selectedLocation);
        const isSearchSuggestion = (selectedLocation as Record<string, unknown>)['group'] === undefined;
        if (isSearchSuggestion) {
          hideSearchPanel?.();
          pushToRoute(selectedLocation as SearchSuggestions, listingParams, router, themeName as ThemeNames, map, features.useUsListings);
        } else {
          onClick(selectedLocation as SearchPrediction);
        }
      }
    }
  };

  useKeyboard(navigateDownListWithKeyboard, 'arrowdown');
  useKeyboard(navigateUpListWithKeyboard, 'arrowup');
  useKeyboard(() => {
    const input: HTMLInputElement | null = document.querySelector('#locationInput');
    // Needed to get input value this way because locationQuery gets emptied for some reason when using the keyboard hook
    if (!input?.value.length) {
      setIsEnterKeyPressed(true);
    } else {
      resultHasContent && !isLoading && !!searchPredictions.length && !isTyping && setIsEnterKeyPressed(true);
    }
  }, 'enter');

  const handlePushToRoute = (searchSuggestion: SearchSuggestions) => {
    hideSearchPanel?.();
    pushToRoute(searchSuggestion, listingParams, router, themeName as ThemeNames, map, features.useUsListings, user!);
  };

  return !isActive ? null :
    <div className={buildClassName(styles.component, className, isAreaSearch && styles['component-small-width'])} data-testid={testIds.suggestedLocationsDropdown}>
      {isLoading && <LoadingSpinner className={styles['loading-spinner']} />}
      {(noResults && locationQuery.length > 2 && !isTyping) &&
        <span className={styles['warning']}>{`Sorry, there are no results for "${locationQuery}"`}</span>
      }
      {resultHasContent && <>
        <LocationDropdownSection
          title={'Locations'}
          Icon={LocationIcon}
          selectedResult={selectedResult}
          searchPredictions={suggestedResults.locations}
          onClick={onClick}
        />
        {!isAreaSearch && <LocationDropdownSection
          title={'Listings'}
          Icon={HomeIcon}
          selectedResult={selectedResult}
          searchPredictions={suggestedResults.listings}
          onClick={onClick}
        />}
        <LocationDropdownSection
          title={'Schools'}
          Icon={NotificationIcon}
          selectedResult={selectedResult}
          searchPredictions={suggestedResults.schools}
          onClick={onClick}
        />
        {!isAreaSearch && <LocationDropdownSection
          title={'Buildings'}
          Icon={BuildingIcon}
          selectedResult={selectedResult}
          searchPredictions={suggestedResults.buildings}
          onClick={onClick}
        />}
      </>}
      {showRecentSearches && (
        <LocationDropdownSection
          title={'Recent Searches'}
          Icon={ClockIcon}
          selectedResult={selectedResult}
          searchPredictions={updatedRecentSearches as SearchPrediction[]}
          onClick={onClick}
        />
      )}
      {showPopularSearches && (
        <LocationDropdownSection
          title={'Popular Searches'}
          Icon={StarIcon}
          selectedResult={selectedResult}
          searchPredictions={[]}
          searchSuggestions={updatedPopularSearches}
          onClick={onClick}
          pushToRoute={handlePushToRoute}
        />
      )}
      {showAgentPredictionSearches && (
        <LocationDropdownSection
          title={'Locations'}
          Icon={StarIcon}
          selectedResult={selectedResult}
          searchPredictions={[]}
          searchAgentPredictions={searchAgentPredictions}
          onClick={onClick}
          pushToRoute={handlePushToRoute}
        />
      )}
      {(!showPopularSearches || (noResults && locationQuery.length > 2 && !isTyping)) && (
        <span className={styles['warning']}><WarningIcon />Don’t see what you’re looking for? Your search might be out of our service area</span>
      )}
    </div>;
}
