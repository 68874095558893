import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const BuildingIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="39" viewBox="0 0 27 39" className={className} onClick={onClick}>
      <g fill="#1A1919" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M2.4 36H24V7.2H2.4V36zM7.2 4.8h12V2.4h-12v2.4zm18 0h-3.6V1.2A1.2 1.2 0 0020.4 0H6a1.2 1.2 0 00-1.2 1.2v3.6H1.2A1.2 1.2 0 000 6v31.2a1.2 1.2 0 001.2 1.2h24a1.2 1.2 0 001.2-1.2V6a1.2 1.2 0 00-1.2-1.2z"></path>
        <path d="M6 9.6a1.2 1.2 0 00-1.2 1.2v21.6a1.2 1.2 0 002.4 0V10.8A1.2 1.2 0 006 9.6"></path>
        <path d="M10.8 9.6a1.2 1.2 0 00-1.2 1.2v21.6a1.2 1.2 0 002.4 0V10.8a1.2 1.2 0 00-1.2-1.2"></path>
        <path d="M19.2 10.8v21.6a1.2 1.2 0 002.4 0V10.8a1.2 1.2 0 00-2.4 0"></path>
        <path d="M15.6 9.6a1.2 1.2 0 00-1.2 1.2v21.6a1.2 1.2 0 002.4 0V10.8a1.2 1.2 0 00-1.2-1.2"></path>
      </g>
    </svg>
  );
};

export default BuildingIcon;
