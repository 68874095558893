import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const LocationIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="36" viewBox="0 0 25 36" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M12.32 8.12a4.205 4.205 0 00-4.2 4.2c0 2.317 1.884 4.201 4.2 4.201 2.316 0 4.2-1.884 4.2-4.2 0-2.316-1.884-4.2-4.2-4.2zm0 5.601c-.772 0-1.4-.628-1.4-1.4 0-.772.628-1.4 1.4-1.4.772 0 1.4.628 1.4 1.4 0 .772-.628 1.4-1.4 1.4zM12.32 0C5.527 0 0 5.527 0 12.32c0 4.936 2.917 9.276 7.246 11.23l3.745 11.332c.422 1.277 2.236 1.278 2.658 0l3.745-11.331a12.319 12.319 0 007.246-11.23C24.64 5.527 19.113 0 12.32 0zm3.439 21.201a1.4 1.4 0 00-.824.867L12.32 29.98l-2.615-7.913a1.4 1.4 0 00-.824-.867 9.52 9.52 0 01-6.081-8.88c0-5.25 4.27-9.52 9.52-9.52s9.52 4.27 9.52 9.52a9.52 9.52 0 01-6.081 8.88z"></path>
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;
