import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const HomeIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M30.369 14.511L16.209.351a1.2 1.2 0 00-1.698 0L.351 14.511a1.2 1.2 0 00.376 1.952l3.51 1.504.564 11.611A1.2 1.2 0 006 30.72h18.72a1.2 1.2 0 001.199-1.142l.563-11.61 3.51-1.505a1.2 1.2 0 00.377-1.952zM17.91 28.32h-5.1v-6.96h5.1v6.96zm6.937-12.263a1.2 1.2 0 00-.725 1.045l-.545 11.218H20.31v-8.16a1.2 1.2 0 00-1.2-1.2h-7.5a1.2 1.2 0 00-1.2 1.2v8.16H7.143L6.6 17.102a1.2 1.2 0 00-.726-1.045l-2.571-1.102L15.36 2.897l12.058 12.058-2.57 1.102z"></path>
        </g>
      </g>
    </svg>
  );
};

export default HomeIcon;
