/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { SEARCH_PREDICTION_SOURCE_GOOGLE } from 'utils/google-maps/types';
import { testIds } from 'constants/test-constants';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import styles from './style.module.scss';
import { removeSpacesInLabel } from 'utils/select-options';
import { buildClassName } from 'utils/build-class-name';

import SearchPrediction, { convertSearchSuggestionToSearchPrediction } from 'data/search-predictions';
import type { SearchAgentPrediction, SearchSuggestions } from 'components/suggested-location-dropdown';

interface Props {
  title: string;
  Icon: any;
  selectedResult: number;
  searchPredictions: SearchPrediction[];
  searchSuggestions?: SearchSuggestions[];
  searchAgentPredictions?: SearchAgentPrediction[];
  onClick: (arg: SearchPrediction) => void;
  pushToRoute?: (searchSuggestion: SearchSuggestions) => void;
}

export default function LocationDropdownSection({ searchPredictions, title, Icon, onClick, selectedResult, searchSuggestions, searchAgentPredictions }: Props) {
  const findMatchingStringsInLocationResult = ({ description, matchedSubstrings }: Record<string, any>) => {
    let html = description;
    if (matchedSubstrings?.length) {
      html = description.slice(0, matchedSubstrings[0].offset);
      matchedSubstrings.forEach(function(match: Record<string, any>, index: number) {
        html += '<strong>' + description.slice(match.offset, match.offset + match.length) + '</strong>';
        const start = match.offset + match.length;
        const nextMatch = matchedSubstrings[index + 1];
        const end = nextMatch ? nextMatch.offset : description.length;
        html += description.slice(start, end);
      });
    }
    return html;
  };

  const formatLocation = (location: string, searchPrediction: SearchPrediction) => {
    const splitLocation = location.split(',');
    let firstArea = splitLocation[0];
    if (searchPrediction.group === 'listings') {
      firstArea = [firstArea, splitLocation[1]].join();
    }
    const restOfDescription = location.replace(firstArea, '');
    firstArea = capitalizeWords(firstArea.toLowerCase());
    return [firstArea, restOfDescription].join('');
  };

  const areResultsFromApple = () => searchPredictions.some(result => result.source === 'apple');
  const areResultsFromGoogle = () => searchPredictions.some(result => result.source === SEARCH_PREDICTION_SOURCE_GOOGLE);

  return (
    <div className={styles.component} data-testid={removeSpacesInLabel(title) + testIds.dropdownSection}>
      {
        (searchPredictions && !!searchPredictions.length || !!searchSuggestions?.length) &&
        <div className={styles['section-title']}>
          <span><Icon /> {title}</span>
          {areResultsFromApple() &&
            <span className={styles['powered-by-apple']}>
              Powered by <img src='/next/assets/images/apple-logo.svg' alt="Apple" />
            </span>
          }
          {areResultsFromGoogle() &&
            <span className={styles['powered-by-google']}>
              <img src='/next/assets/images/powered_by_google.png' alt="Google" />
            </span>
          }
        </div>
      }
      {
        searchPredictions?.map((searchPrediction, index) => {
          const [statusLabel, salesLabel] = searchPrediction.label?.split('- ') || [];
          const isInactive = statusLabel?.includes('inactive');
          const matchedLocation = findMatchingStringsInLocationResult(searchPrediction);
          const locationText = formatLocation(matchedLocation, searchPrediction);
          return (
            <div
              className={`${styles['location-option']} ${selectedResult === searchPrediction.identifier ? styles['active'] : ''}`}
              key={index}
              onClick={() => onClick(searchPrediction)}
            >
              <div dangerouslySetInnerHTML={{ __html: locationText }} />
              <div className={styles.label}>
                {statusLabel} {salesLabel && <span className={isInactive ? styles.sold : styles.sale}>[{salesLabel}]</span>}
              </div>
            </div>
          );
        })
      }
      {
        searchSuggestions?.map((searchSuggestion, index) => {
          const { label } = searchSuggestion;
          const [suggestionLabel, suggestionTypeLabel] = label?.split('- ') || [];
          return (
            <div key={index} className={styles['suggested-link']}>
              <a className={`${styles['location-option']} ${selectedResult === searchSuggestion.identifier ? styles['active'] : ''}`} 
                onClick={async () => {
                  // In order to save our search suggestions as a "recent search" when clicked, we must treat it as a search prediction
                  const searchPrediction = await convertSearchSuggestionToSearchPrediction(searchSuggestion) as SearchPrediction;
                  onClick(searchPrediction);
                }}>
                {suggestionLabel}
                <div className={styles.label}>{suggestionTypeLabel}</div>
              </a>
            </div>
          );
        })
      }
      {
        searchAgentPredictions?.map((searchAgentPrediction, index) => {
          const { label } = searchAgentPrediction;
          const [suggestionLabel, suggestionTypeLabel] = label?.split('- ') || [];
          return (
            <div key={index} className={styles['suggested-link']} onClick={() => onClick(searchAgentPrediction as SearchPrediction)}>
              <a className={buildClassName(styles['location-option'], selectedResult === searchAgentPrediction.identifier ? styles['active'] : '')}>
                {suggestionLabel}
                <div className={styles.label}>{suggestionTypeLabel}</div>
              </a>
            </div>
          );
        })
      }
    </div>
  );
}
