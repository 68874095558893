import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const StarIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 477.899 477.899" version="1.1" viewBox="0 0 477.899 477.899" xmlSpace="preserve" className={className} onClick={onClick}>
      <path d="M469.11 170.122a34.592 34.592 0 00-21.058-11.249v-.017l-127.01-17.545-50.261-120.098c-7.339-17.579-27.539-25.88-45.117-18.541a34.492 34.492 0 00-18.541 18.541l-50.261 120.098-127.01 17.545C10.929 161.49-2.276 178.967.358 197.89a34.592 34.592 0 0011.249 21.058l95.693 85.333-19.662 133.904a34.133 34.133 0 0015.838 34.355 34.013 34.013 0 0037.7-.7l97.775-66.492 97.775 66.56c15.777 10.708 37.248 6.599 47.957-9.178a34.527 34.527 0 005.582-24.477l-19.661-133.956 95.693-85.333c14.264-12.71 15.524-34.578 2.813-48.842zm-128.1 114.89a17.07 17.07 0 00-5.513 15.224l20.48 143.36-107.383-73.011a17.065 17.065 0 00-19.183 0l-108.015 72.567 21.009-142.984a17.065 17.065 0 00-5.513-15.155l-102.4-92.297 136.67-18.859a17.064 17.064 0 0013.414-10.325l54.579-129.399a.764.764 0 01.137.256l54.016 129.075a17.066 17.066 0 0013.414 10.325l136.841 19.695-102.553 91.528z"></path>
    </svg>
  );
};

export default StarIcon;
